import { render, staticRenderFns } from "./MyList.vue?vue&type=template&id=34a360a0&scoped=true&MaterialType=0"
import script from "./MyList.vue?vue&type=script&lang=js&MaterialType=0"
export * from "./MyList.vue?vue&type=script&lang=js&MaterialType=0"
import style0 from "./MyList.vue?vue&type=style&index=0&id=34a360a0&scoped=true&lang=css&MaterialType=0"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a360a0",
  null
  
)

export default component.exports